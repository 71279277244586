<template>
  <div class="merchtishi">
    <van-nav-bar
      :title="pagetitle"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="content">
      <div class="title_text">
        <van-icon name="checked" size="60" color="#07c160" />
        <van-cell title="恭喜您入驻完成" :border="false" />
      </div>
      <div class="login_area">
        <van-cell title="登录账号信息" :border="false" />
      </div>
      <div class="list">
        <van-cell-group :border="false">
          <van-cell
            :border="false"
            title="登录账号"
            :value="merchinfo.username"
          />
          <van-cell
            :border="false"
            title="登录密码"
            value="您的设置"
          />
        </van-cell-group>
      </div>
      <div class="login_area">
        <van-cell title="PC端登录地址" :border="false" />
      </div>
      <div class="login_area_url">
        <van-cell :title="merchinfo.pclogin" :border="false">
        </van-cell>
      </div>
      <div class="copay_url">
        <input type="text" v-model="merchinfo.pclogin" v-show="false" />
        <van-button
          round
          size="mini"
          v-clipboard:copy="merchinfo.pclogin"
          v-clipboard:success="loginonCopy"
          v-clipboard:error="loginonError"
        >复制</van-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "merchTishi",
  data() {
    return {
      shareid: 0,
      merchid: 0,
      merchinfo: {},
      pagetitle: ""
    }
  },
  mounted() {
    if (this.$route.query.shareid) {
      this.shareid = this.$route.query.shareid
    }else if (localStorage.getItem("DK_MEMBERID")) {
      this.shareid = localStorage.getItem("DK_MEMBERID");
    }
    this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;
    this.pagetitle = this.$route.query.pagetitle ? this.$route.query.pagetitle : "";
    this.$parent.getmerchname(this.merchid, this.pagetitle);
    this.init();
  },
  methods: {
    init() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID") ? localStorage.getItem("DK_UID") : "";
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(_that.$store.state.domain + "web/user/get_merch_user")
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            _that.merchinfo = res.data.data;
          }else{
            _that.$toast(res.data.msg ? res.data.msg : "未找到店铺信息");
          }
          
        })
        .catch(err => {
          console.log(err);
        })
    },

    loginonCopy() {
      this.$toast("复制成功");
    },
    loginonError() {
      this.$toast("复制失败");
    },
    // 返回上一页
    onClickLeft(){
      this.$router.go(-1);
    }
  }
}
</script>
<style lang="less">
.merchtishi {
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  .van-nav-bar .van-icon {
    color: #333;
  }
  .content {
    overflow: hidden;
    background-color: #fff;
    padding-top: 10%;
    .title_text,
    .login_area {
      .van-cell__title {
        flex: 1;
        text-align: center;
        padding-bottom: 10px;
      }
    }
    .title_text {
      .van-cell__title {
        font-size: 16px;
      }
    }
    .login_area {
      padding-top: 10px;
    }
    .list {
      padding: 0 16px;
      .van-cell {
        background-color: #FAF3ED;
        .van-cell__title {
          width: 80px;
          text-align: left;
          color: #EE9F6A;
        }
        .van-cell__value {
          flex: 1;
          text-align: left;
          color: #EE9F6A;
        }
      }
    }
    .login_area_url {
      padding: 0 16px;
      .van-cell {
        background-color: #FAF3ED;
        .van-cell__title {
          flex: 1;
          text-align: left;
          color: #EE9F6A;
        }
      }
    }
    .copay_url {
      margin: 0 16px;
      background-color: #FAF3ED;
      padding-bottom: 10px;
      .van-button {
        padding: 0 20px;
        background-color: #FAF3ED;
        border-color: #EE9F6A;
        color: #EE9F6A;
      }
    }
  }
}
</style>